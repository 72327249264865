import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentBottomSheet.figmaUrl.android} codeUrl={checklists.componentBottomSheet.codeUrl.android} checklists={checklists.componentBottomSheet.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`A BottomSheet is a UI component in mobile apps that slides up from the bottom of the screen to display additional information or actions. It can be either persistent, staying partially visible, or modal, covering the bottom of the screen and requiring dismissal to interact with the rest of the app.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/bottomsheet/android/android-compose-bottomsheet.gif",
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The following is code to implement Rating component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var showBottomSheet by remember { mutableStateOf(false) }

LgnButton(
    onClick = {
        showBottomSheet = !showBottomSheet
    },
    label = "Open BottomSheet"
)

LgnModalBottomSheet(
    visible = showBottomSheet,
    onDismissRequest = { showBottomSheet = false },
    header = {
        LgnBottomSheetDefaults.Header(title = { Text(text = "Title") })
    },
    footer = {
        LgnButtonGroup(firstTitleButton = "Yes")
    }
) {
    Text(text = "Content")
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`visible`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controls visibility in this bottom sheet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onDismissRequest`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`() -> Unit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Executes when the user clicks outside of the bottom sheet, after sheet animates to hidden.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}{`[Modifier]`}{` to be applied to the bottom sheet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sheetState`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SheetState`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The state of the bottom sheet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sheetMaxWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[Dp]`}{` that defines what the maximum width the sheet will take.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The shape of the bottom sheet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tonalElevation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The tonal elevation of this bottom sheet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnBottomSheetColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[LgnBottomSheetColor]`}{` that will be used to resolve the colors used for this alert in different states.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`dragHandle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@Composable (() -> Unit)?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional visual marker to swipe the bottom sheet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`windowInsets`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WindowInsets`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Window insets to be passed to the bottom sheet window.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`properties`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ModalBottomSheetProperties`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[ModalBottomSheetProperties]`}{` for further customization of this modal bottom sheet’s behavior.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@Composable (ColumnScope.() -> Unit)?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The header to be displayed inside the bottom sheet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`footer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@Composable (ColumnScope.() -> Unit)?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The footer to be displayed inside the bottom sheet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@Composable ColumnScope.() -> Unit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content to be displayed inside the bottom sheet.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      